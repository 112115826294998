import React from 'react';
import { Select, Space, Typography, Alert } from 'antd';
import { rowStyles } from '../assets/styles/custom_styles';

const { Text } = Typography;

export default function SelectServices({ services=[], confirm, service }) {
    const handleSelectChange = (value) => {
      const selected_service = services?.find(
        (row) => row.id === value
      )
      if(selected_service) confirm(selected_service);
    };
    
    const handleSearch = (value) => {
        console.log('search:', value);
    };

    const options = services?.map(
        (row) => ({ value: row?.id, label: row?.name })
    )
    
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Space direction="vertical" style={ rowStyles }>
        <Text strong>What do you want to report?</Text>
        <Select
            showSearch
            size="large"
            placeholder="Select a service"
            optionFilterProp="children"
            onChange={handleSelectChange}
            onSearch={handleSearch}
            filterOption={filterOption}
            options={options}
            style={{
                width: "100%"
            }}
        />
        { !service && 
            <Alert 
              message="Please select a service type." 
              type="info" 
              showIcon 
              style={{
                marginTop: "0.25rem",
                width: "max-content"
              }}
            />
        }
    </Space>
  )
}
