import { Alert, Space } from "antd"
import { rowStyles } from "../assets/styles/custom_styles"

export const ThankYou = ({title}) => {
    return (
        <Space direction="vertical" style={ rowStyles }>
            <Alert 
              showIcon 
              closable 
              message={ "Thank You" } 
              description={ title }
              type="success" 
            />
        </Space>
    )
}