import React from 'react';
import { Space, Typography } from "antd";
import { customColors, rowStyles } from '../assets/styles/custom_styles';

const { Text } = Typography;

export default function Footer() {
  return (
    <Space 
           direction="vertical" 
           align="center" 
           style={{ 
            ...rowStyles ,
            borderBottom: "5px solid " + customColors?.primary
           }}
        >
            <Text>By reporting this, you and the administrator of your account have agreed to Workforce's Terms & Conditions and Privacy Policy</Text>
        </Space>
  )
}
