import { Space, Typography } from "antd";
import { rowStyles } from "../assets/styles/custom_styles";
import dayjs from "dayjs";
const { Title, Text } = Typography;

export const SubHeader = ({ title }) => {
    return (
        <Space 
          direction="vertical" 
          align="center" 
          style={{ ...rowStyles, gap: "0.25rem" }}
        >
            <Title level={ 2 } style={{ marginBlock: 0 }}>
                { title }
            </Title>
            <Text strong>
                { dayjs(new Date()).format("DD MMM YYYY") }
            </Text>
        </Space>
    )
}