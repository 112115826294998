import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from "antd";
import { customColors } from './assets/styles/custom_styles';

const custom_theme ={
  components: {
    Button: {
      primaryShadow: "none",
      paddingInlineLG: "1.5rem"
    }
  },
  token: {
    colorPrimary: customColors.primary,
    colorBorder: customColors.border,
    colorTextPlaceholder: customColors.light_text,
    fontSizeIcon: 16
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ConfigProvider theme={ custom_theme }>
        <App />
     </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
