import { Avatar, Space } from "antd";
import { customColors, rowStyles } from "../assets/styles/custom_styles";

export const Header = ({ title }) => {
    return (
        <Space 
           direction="vertical" 
           align="center" 
           style={{ 
            ...rowStyles ,
            borderTop: "5px solid " + customColors.primary
           }}
        >
            <Avatar
                shape="square"
                size={ 100 }
                alt={ title } 
                src="./images/logo.png" 
            />
        </Space>
    )
}