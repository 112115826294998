import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Flex, Input, Button, Space, Typography } from "antd";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { AimOutlined } from '@ant-design/icons';
import { API_KEY } from '../map.config';

const { Text } = Typography;

const defaultResult = {
    set: false,
    address: null,
    lat: 27.700769,
    lng: 85.300140
}

const MapContainer = forwardRef((props, ref) =>{

    // search for address
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResult, setSearchResult] = useState(defaultResult);

    // locate address
    const [locationError, setLocationError] = useState(null);
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              // success
              async ({coords}) => {
                try {
                    const res = await fetch(
                      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${ API_KEY }`
                    );
                    const res_json = await res.json();
                    const { formatted_address, geometry } = res_json.results[0] || {};
                    setSearchResult({
                        set: true,
                        address: formatted_address,
                        lat: geometry?.location.lat,
                        lng: geometry?.location.lng
                    });
                    setSearchQuery(formatted_address);
                 } catch (error) {
                     console.error(error)
                 } finally {
                    setLocationError(null)
                 }
              },
              () => {
                 setLocationError("Unable to retrieve your location.");
              });
          } else {
            console.error("Geolocation not supported");
          }
    }

    //actions
    useImperativeHandle(ref, () => ({
        getAddressSelection() {
            return searchResult
        }
    }));

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY
    })

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
        setLocationError(null);
    }

    const searchAddress = async () => {
       try {
        const res = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${searchQuery}&key=${ API_KEY }`
        );
        const res_json = await await res.json();
        const { formatted_address, geometry } = res_json.results[0] || {};
        setSearchResult({
            set: true,
            address: formatted_address,
            lat: geometry?.location.lat,
            lng: geometry?.location.lng
        });
        setSearchQuery(formatted_address);
       } catch (error) {
           console.error(error);
       } finally {
           setLocationError(null);
       }
    }
    
    return (
        <div style={{ marginBlock: "1rem" }}>
            <Flex gap={16} style={{ marginBottom: "1.25rem" }}>
                <Space direction="vertical" style={{ flex: 1, rowGap: "0.25rem" }}>
                    <Input 
                        placeholder="Search location..." 
                        onChange={ handleInputChange } 
                        value={ searchQuery }
                        style={{ width: "100%" }} 
                    />
                    { !!locationError && 
                          <Text type="danger">{ locationError }</Text>
                    }
                </Space>
                <Button 
                    type="primary"
                    onClick={ searchAddress }
                >
                    Search
                </Button>
                <Button 
                    type="primary" 
                    icon={ <AimOutlined /> } 
                    style={{ width: "35px", flexShrink: 0 }}
                    onClick={ getCurrentLocation }
                    ghost 
                />
            </Flex>
         
            { isLoaded &&
                <GoogleMap
                    mapContainerStyle={{ height: "300px", width: "100%" }}
                    center={{
                        lat: searchResult.lat,
                        lng: searchResult.lng
                    }}
                    zoom={11}
                >
                    {
                        searchResult.set && 
                            <Marker 
                               position={{
                                lat: searchResult.lat, 
                                lng: searchResult.lng
                               }} 
                            />
                    }
                   
                </GoogleMap>
            }
        </div>
    )
});

export default MapContainer;
