export const customColors = {
    primary: "hsl(263.4 70% 50.4%)",
    light_text: "#8c8c8c",
    border: "#bfbfbf",
    clear_icon: "rgba(0, 0, 0, 1)"
};

export const bodyStyles = {
    backgroundColor: "rgb(233, 238, 246)",
    minHeight: "100dvh"
}

export const containerStyles = { 
    padding: 20, 
    maxWidth: "768px", 
    margin: "0 auto" 
}

export const rowStyles = {
    backgroundColor: "#ffffff",
    padding: "1rem",
    borderRadius: "0.75rem"
}